import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonViewWillEnter } from '@ionic/react';
import React, { useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from '../data/connect';
import Fetch from '../util/Fetch';
import Permission from '../util/Permission';
import { syncOutline } from 'ionicons/icons';
import { Button, Modal } from 'react-bootstrap';

interface OwnProps {
}

interface StateProps {
}

interface DispatchProps {
}

interface SessionListProps extends OwnProps, StateProps, DispatchProps { };

const AllMachineSyncHistory: React.FC<SessionListProps> = () => {
	const [syncHistoryData, setSyncHistoryData] = useState<any[]>([]);
	const [syncHistorySearchData, setSyncHistorySearchData] = useState<any[]>([]);
	const [syncPermission, setSyncPermission] = useState<boolean>(false);
	const [showLoading, hideLoading] = useIonLoading();
	const [attendanceMessage, setAttendanceMessage] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [showIonAlert] = useIonAlert();
	const [selectedMachineId, setSelectedMachineId] = useState(0);

	const handleClose = () => setShowModal(false);
  	const handleShow = () => setShowModal(true);

	let fetchData = () => {
		let syncHistoryQuery = "{machineSyncHistory{idHrFpMachines,ipAddress,workStationName,createDate,status,userCount,attendanceCount}}";

		Fetch(syncHistoryQuery).then(data => {
			setSyncHistoryData(data.data.machineSyncHistory);
			setSyncHistorySearchData(data.data.machineSyncHistory);
		});
	}

	let userInfo = JSON.parse(window.localStorage.getItem("hrFpUserInfo") || "{}");

	let checkPermission = async () => {
		await Permission(userInfo.idUsers, "machine_sync_history").then(data => {
			if (!data.data.singlePagePermission.idHrFpUsersPermission) {
				toast.error("Access denied.");
				window.location.href = "/machines";
			}
		});
	}

	let checkSyncPermission = async () => {
		await Permission(userInfo.idUsers, "sync_machine").then(data => {
			if (data.data.singlePagePermission.idHrFpUsersPermission) {
				setSyncPermission(true);
			} else {
				setSyncPermission(false);
			}
		});
	}

	useIonViewWillEnter(async () => { 
		await checkPermission();
		await checkSyncPermission();
		fetchData();
	});

	const pageRef = useRef<HTMLElement>(null);

	const filterDate = (date: string) => {
		let newDataSet = syncHistorySearchData.filter(data => {
			return (new Date(data.createDate)).toString().substring(15, 0).toUpperCase().includes(date.toUpperCase());
		});
		
		setSyncHistoryData(newDataSet);
	}

	const filterIpAddress = (ip: string) => {
		let newDataSet = syncHistorySearchData.filter(data => {
			return data.ipAddress.includes(ip);
		});
		
		setSyncHistoryData(newDataSet);
	}
	const filterWorkStation = (ws: string) => {
		let newDataSet = syncHistorySearchData.filter(data => {
			return data.workStationName.toLocaleString().toUpperCase().includes(ws.toUpperCase());
		});
		
		setSyncHistoryData(newDataSet);
	}

	const getDateDifference = (date1: string, date2: string) => {
		let date1Date = new Date(date1);
		let date2Date = new Date(date2);
		let difference = date2Date.getTime() - date1Date.getTime();
		let days = Math.floor(difference / (1000 * 60 * 60 * 24));
		return days;
	}

	let getPendingAttendance = (idHrFpMachines: number) => {
		setSelectedMachineId(idHrFpMachines);
		showLoading();
		let syncQuery = `query{
			pendingAttendance(idHrFpMachines:${idHrFpMachines}){
				success
				message
			}
		}`

		Fetch(syncQuery).then(async data => {
			if (data.data.pendingAttendance.success) {
				setAttendanceMessage(data.data.pendingAttendance.message);
				handleShow();
			} else {
				showIonAlert({
					header: "Error",
					message: data.data.pendingAttendance.message,
					buttons: ["Ok"]
				})
			}
			hideLoading()
		});
	}

	let handleSubmitSyncData = () => {
		handleClose();
		syncMachineData();
	}

	let syncMachineData = () => {
		showLoading();
		let syncQuery = `mutation{
			syncMachine(idHrFpMachines:${selectedMachineId}){
				success
				message
			}
		}`

		Fetch(syncQuery).then(async data => {
			if (data.data.syncMachine.success) {
				fetchData();
			} else {
				showIonAlert({
					header: "Error",
					message: data.data.syncMachine.message,
					buttons: ["Ok"]
				})
			}
			hideLoading()
		});
	}

	const failedStyle = {
		backgroundColor: "red",
		color: "white"
	}

	const startedStyle = {
		backgroundColor : "yellow"
	}

	return (
		<IonPage ref={pageRef} id="machine-sync-history">
			<Toaster position="top-right" reverseOrder={false}/>
			<IonHeader translucent={true}>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Machines Sync History</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<Table bordered hover size='sm'>
					<thead>
						<tr>
							<th>Date</th>
							<th>Days Since Last Sync</th>
							<th>IP Address</th>
							<th>Work Station</th>
							<th>Attendance Count</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
						<tr>
							<th><input type="search" onChange={(e) => filterDate(e.target.value)}  /></th>
							<th>&nbsp;</th>
							<th><input type="search" onChange={(e) => filterIpAddress(e.target.value)}  /></th>
							<th><input type="search" onChange={(e) => filterWorkStation(e.target.value)}  /></th>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{syncHistoryData ? (syncHistoryData.map((value) => (
							<tr key={`syncHistory-${value.idHrFpMachines}`} style={ (value.status === "failed") ? failedStyle : (value.status === "started") ? startedStyle : {}}>
								<td>{(new Date(value.createDate)).toString().substring(24, 0)}</td>
								<td style={{textAlign:"right"}}>{getDateDifference(value.createDate, new Date().toString())}</td>
								<td>{value.ipAddress}</td>
								<td>{value.workStationName}</td>
								<td style={{textAlign:"right"}}>{value.attendanceCount}</td>
								<td>{value.status}</td>
								<td>
								{syncPermission ? <IonButton onClick={() => getPendingAttendance(value.idHrFpMachines)} title="Sync">
									<IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
								</IonButton> : null }
								</td>
							</tr>
						))) : (<tr><td colSpan={5}>No data</td></tr>)}
					</tbody>
				</Table>
			</IonContent>

			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
				<Modal.Title>Pending Attendance</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Total {attendanceMessage}</h4>
					<h5>Do you want to sync pending attendance?</h5>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSubmitSyncData}>
					Yes, Sync
				</Button>
				</Modal.Footer>
			</Modal>
		</IonPage>
	);
};

export default connect<OwnProps, {}, DispatchProps>({
	mapDispatchToProps: {
	},
	component: AllMachineSyncHistory
})
