import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { connect } from '../data/connect';

interface OwnProps {
	syncHistory: any[] | undefined;
	searchText: string;
}

interface StateProps {
	
}

interface DispatchProps {
}

interface SessionListProps extends OwnProps, StateProps, DispatchProps { };

const SessionList: React.FC<SessionListProps> = ({ syncHistory, searchText }) => {

	useEffect(() => {})

	const failedStyle = {
		backgroundColor: "red",
		color: "white"
	}

	const startedStyle = {
		backgroundColor : "yellow"
	}

	return (
		<>
			<Table bordered hover size='sm'>
				<thead>
					<tr>
						<th>Date</th>
						<th>Attendance Count</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{console.log(searchText)}
					{syncHistory ? syncHistory.map((value, index) => {
						if(searchText && (new Date(value.createDate)).toLocaleString().toLowerCase().indexOf(searchText.toLowerCase()) === -1 && value.status.toLowerCase().indexOf(searchText.toLowerCase()) === -1){
							
							return null;
						}
						return (<tr key={`syncHistory-${index}`} style={ (value.status === "failed") ? failedStyle : (value.status === "started") ? startedStyle : {}}>
							<td>{(new Date(value.createDate)).toLocaleString()}</td>
							<td>{value.attendanceCount}</td>
							<td>{value.status}</td>
						</tr>)
					}) : (<tr><td colSpan={5}>No data</td></tr>)}
				</tbody>
			</Table>
		</>
	);
};

export default connect<OwnProps, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
	}),
	mapDispatchToProps: ({
	}),
	component: SessionList
});
