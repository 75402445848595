let Permission = async (idUsers: number, page : string)=>{
	return (fetch(process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : process.env.react_app_api_url!, {
		method: "post",
		body: JSON.stringify({ "query": `query{singlePagePermission(idUsers: ${idUsers}, slug: "${page}"){idHrFpPages,idHrFpUsersPermission}}`, "variables": null }),
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': "Bearer "+window.localStorage.getItem("token")
		}
	}).then(response => {
		if(response.status === 401){
			window.location.href="/login";
		}

		return response.json()
	})
	.catch(e => {console.log(e)})
	.then(data => data.errors ? {data: {success: false, message: data.errors.join(",")}} : data))
}

export default Permission;