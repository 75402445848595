import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRow, IonTitle, IonToggle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import AsyncSelect from 'react-select/async';
import { connect } from '../data/connect';
import Fetch from '../util/Fetch';
import Permission from '../util/Permission';

interface OwnProps {
}

interface StateProps {
}

interface DispatchProps {
}

interface SessionListProps extends OwnProps, StateProps, DispatchProps { };

const AccessControl: React.FC<SessionListProps> = () => {
	const [selectedUser, setSelectedUser] = useState<any>({});
	const [pagesData, setPagesData] = useState<any[]>([]);

	interface UserOptions {
		readonly idUsers: number; 
		readonly username: string;
		readonly email: string;
		readonly firstName: string;
		readonly lastName: string;
	}

	let userInfo = JSON.parse(window.localStorage.getItem("hrFpUserInfo") || "{}");

	let checkPermission = async () => {
		await Permission(userInfo.idUsers, "manage_access_control").then(data => {
			if (!data.data.singlePagePermission.idHrFpUsersPermission) {
				toast.error("Access denied.");
				window.location.href = "/machines";
			}
		});
	}

	useIonViewWillEnter(async () => {
		await checkPermission();
	});

	const pageRef = useRef<HTMLElement>(null);

	const userOptions = (inputValue: string, callback: (options: UserOptions[]) => void) => {
		let searchUserQuery = `{searchUsers(searchKey: "${inputValue}"){idUsers, username, email, firstName, lastName}}`;

		Fetch(searchUserQuery).then(data => {
			data.data.searchUsers.map((user: any) => {
				user.value = user.idUsers;
				user.label = `${user.firstName} ${user.lastName} (${user.email})`;
				return user;
			});
			setTimeout(() => {
				callback(data.data.searchUsers);
			}, 1000)
		});
	}

	const getUserPermission = (user: any) => {
		let userPermissionQuery = `{userPermission(id: ${user.idUsers}){idHrFpPages, pageTitle, idHrFpUsersPermission, sequence, idParent}}`;

		Fetch(userPermissionQuery).then(data => {
			setPagesData(data.data.userPermission);
		});
	}

	const onUserSelect = (user: any) => {
		setPagesData([]);
		setSelectedUser(user);
		getUserPermission(user);
	}

	const onPermissionChange = (event: any) => {
		let savePermissionQuery = `mutation{changePermission(permission: ${event.detail.checked}, idUsers:${selectedUser.idUsers}, idHrFpPages: ${event.detail.value.idHrFpPages}){success,message}}`;

		console.log(event.detail);

		Fetch(savePermissionQuery).then(data => {
			if (data.data.changePermission.success) {
				toast.success('Permission changed successfully');
				event.detail.value.idHrFpUsersPermission = data.data.changePermission.message;
			} else {
				toast.error(data.data.changePermission.message);
			}
		});
	}

	return (
		<IonPage ref={pageRef} id="machine-sync-history">
			<Toaster position="top-right" reverseOrder={false}/>
			<IonHeader translucent={true}>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Access Control</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonGrid>
					<IonRow>
						<IonCol>
							<AsyncSelect cacheOptions loadOptions={userOptions} onChange={onUserSelect}/>
						</IonCol>
					</IonRow>
					<IonRow>
						{pagesData.length ?
						<Table striped bordered hover size='sm'>
							<thead>
								<tr>
									<th>Menu Name</th>
									<th style={{textAlign:"center"}}>Permission</th>
								</tr>
							</thead>
							<tbody>
								{pagesData ? (pagesData.map((value) => (
									<tr key={`userPermission-${value.idHrFpPages}`}>
										<td>{value.pageTitle}</td>
										<td style={{textAlign:"center"}}><IonToggle checked={value.idHrFpUsersPermission} onIonChange={onPermissionChange} value={value}></IonToggle></td>
									</tr>
								))) : (<tr><td colSpan={2}>No data</td></tr>)}
							</tbody>
						</Table>
						: ""}
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default connect<OwnProps, {}, DispatchProps>({
	mapDispatchToProps: {
	},
	component: AccessControl
})
