import { IonItemDivider, IonItemGroup, IonLabel, IonList } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from '../data/connect';
import { MachineAttendance, MachineUser } from '../models/FingerprintMachine';
interface OwnProps {
	listType: 'users' | 'attendance';
	users: MachineUser[] | undefined;
	attendance: MachineAttendance[] | undefined;
	searchText: string;
}

interface StateProps {
	favoriteSessions: number[];
}

interface DispatchProps {
}

interface SessionListProps extends OwnProps, StateProps, DispatchProps { };

const SessionList: React.FC<SessionListProps> = ({ users, attendance, favoriteSessions, listType, searchText }) => {

	const [attendanceDates, setAttendanceDates] = useState<string[]>([]);

	let fetchGroups = () => {
		setAttendanceDates(Array.from(new Set([...(attendance ? attendance.map(value => value.attendanceTimestamp.split(" ")[0]) : [])]))); // making unique
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => { fetchGroups(); }, []);

	return (
		<>
			<IonList>
				{attendanceDates.map((group, index: number) => {
					return (
					<IonItemGroup key={`group-${index}`}>
						<IonItemDivider sticky>
							<IonLabel>
								{(new Date(group)).toString().substring(15, 0)}
							</IonLabel>
						</IonItemDivider>
						<Table className='table-sm' striped bordered hover>
							<thead>
								<tr>
									<th>Name</th>
									<th>User ID</th>
									<th>Time</th>
								</tr>
							</thead>
							<tbody>
								{attendance ? (attendance.map((value) =>  {
									if (value.attendanceTimestamp.indexOf(group) === -1) return false;
									if(searchText && value.name.toLowerCase().indexOf(searchText.toLowerCase()) === -1 && value.userId.toLowerCase().indexOf(searchText.toLowerCase()) === -1){
										return null;
									}
									return (
									<tr key={`attendance-${value.uid}`}>
										<td>{value.name}</td>
										<td>{value.userId}</td>
										<td>{`${String(new Date(value.attendanceTimestamp).getHours()).padStart(2, '0')}:${String(new Date(value.attendanceTimestamp).getMinutes()).padStart(2, '0')}`}</td>
									</tr>
								)})) : (<tr><td colSpan={4}>No data</td></tr>)}
							</tbody>
						</Table>
					</IonItemGroup>)
					})}
			</IonList>
		</>
	);
};

export default connect<OwnProps, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		favoriteSessions: state.data.favorites
	}),
	mapDispatchToProps: ({
	}),
	component: SessionList
});
