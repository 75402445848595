import { IonButton, IonCol, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonText, useIonAlert, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from '../data/connect';
import { setMenuEnabled } from '../data/sessions/sessions.actions';
import { setIsLoggedIn, setUsername } from '../data/user/user.actions';
import Fetch from '../util/Fetch';
import './Login.scss';

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
	setIsLoggedIn: typeof setIsLoggedIn;
	setUsername: typeof setUsername;
	setMenuEnabled: typeof setMenuEnabled;
}

interface LoginProps extends OwnProps, DispatchProps { }

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, setMenuEnabled, history, setUsername: setUsernameAction }) => {

	if (window.localStorage.getItem('token')) { window.location.href = '/machines'; };
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [usernameError, setUsernameError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [showAlert] = useIonAlert();

	useIonViewWillEnter(() => {
		setMenuEnabled(false);
	});

	const getUserMenu = async (idUsers: number) => {
		let userMenu = `{menus(idUsers: ${idUsers}){idHrFpPages, pageTitle, idHrFpUsersPermission, sequence, idParent, pageLink, icon}}`;

		await Fetch(userMenu).then((data) => {
			data.data.menus.map((menu: any) => {
				menu.title = menu.pageTitle;
				menu.path = menu.pageLink;
			
				return menu;
			});
		  
			window.localStorage.setItem('hrFpUserMenu', JSON.stringify(data.data.menus));
		});
	}

	const login = async (e: React.FormEvent) => {
		e.preventDefault();
		setFormSubmitted(true);
		if (!username) {
			setUsernameError(true);
		}
		if (!password) {
			setPasswordError(true);
		}

		if (username && password) {

			console.log(process.env);
			fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : process.env.react_app_api_url!) + 'login', {
				method: "post",
				body: JSON.stringify({ "query": `{login(email: "${username}", password:"${password}"){success,firstName,lastName,token,idUsers}}`, "variables": null }),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				}
			})
				.then(response => response.json())
				.then(async data => {
					console.log(data);
					if (data.errors) {
						showAlert({
							header: 'Login failed',
							message: data.errors.map((error: any) => error.message).join(','),
							buttons: ['Ok'],
						})
					}
					else if (!data.data.login.success) {
						showAlert({
							header: 'Login failed',
							message: "Wrong email or password",
							buttons: ['Ok'],
						})
					} else {
						window.localStorage.setItem('hrFpUserInfo', JSON.stringify(data.data.login));
						window.localStorage.setItem('token', data.data.login.token);
						await getUserMenu(data.data.login.idUsers);
						await setIsLoggedIn(true);
						await setUsernameAction(data.data.login.firstName);
						await setMenuEnabled(true);
						history.push('/machines', { direction: 'none' });
					}
				})
		}
	};

	return (
		<IonPage id="login-page">
			<IonContent fullscreen>
				<div className="login-container">
					<div className="login-container-main">
						<div className="login-logo">
							<img src="assets/img/fingerprint.png" alt="Ionic logo" />
						</div>

						<form noValidate onSubmit={login}>
							<IonList>
								<IonItem>
									<IonLabel position="stacked" color="primary">Email</IonLabel>
									<IonInput name="username" type="text" value={username} spellCheck={false} autocapitalize="off" onIonChange={e => setUsername(e.detail.value!)}
										required>
									</IonInput>
								</IonItem>

								{formSubmitted && usernameError && <IonText color="danger">
									<p className="ion-padding-start">
										Username is required
									</p>
								</IonText>}

								<IonItem>
									<IonLabel position="stacked" color="primary">Password</IonLabel>
									<IonInput name="password" type="password" value={password} onIonChange={e => setPassword(e.detail.value!)}>
									</IonInput>
								</IonItem>

								{formSubmitted && passwordError && <IonText color="danger">
									<p className="ion-padding-start">
										Password is required
									</p>
								</IonText>}
							</IonList>

							<IonRow>
								<IonCol>
									<IonButton type="submit" expand="block">Login</IonButton>
								</IonCol>
								<IonCol>
									<IonButton routerLink="/signup" color="light" expand="block">Signup</IonButton>
								</IonCol>
							</IonRow>
						</form>
					</div>
				</div>
			</IonContent>

		</IonPage>
	);
};

export default connect<OwnProps, {}, DispatchProps>({
	mapDispatchToProps: {
		setIsLoggedIn,
		setUsername,
		setMenuEnabled
	},
	component: Login
})