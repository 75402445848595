import React from 'react';
import { IonBadge, IonRow, IonCol, IonCard, IonCardHeader, IonItem, IonLabel, IonAvatar, IonCardContent, IonList, IonIcon } from '@ionic/react';
import { Machine } from '../models/FingerprintMachine';
import { personCircleOutline } from 'ionicons/icons';

interface MachineItemProps {
	machine: Machine;
}

const MachineItem: React.FC<MachineItemProps> = ({ machine }) => {
	return (
		<>
			<IonCard className="speaker-card">
				<IonCardHeader>
					{/* <IonItem button detail={false} lines="none" className="speaker-item" routerLink={`/tabs/speakers/${machine.idHrFpMachines}`}> */}
					<IonItem button detail={false} lines="none" className="speaker-item" routerLink={`/machine/` + machine.idHrFpMachines}>
						<IonAvatar slot="start">
							<img src="assets/img/uFace800.png" alt="Speaker profile pic" />
						</IonAvatar>
						<IonLabel>
							<h2>{machine.ipAddress}</h2>
							<p>{machine.machineDeviceName}</p>
						</IonLabel>
					</IonItem>
				</IonCardHeader>

				<IonCardContent>
					<IonList lines="inset">
						<IonItem detail={false} routerLink={`/machine/` + machine.idHrFpMachines}>
							<IonLabel>
								<IonRow>
									<IonCol style={{textAlign: "center"}} size="4">
										{machine.online ? (<IonBadge color="success">Online</IonBadge>) : (<IonBadge color="danger">Offline</IonBadge>)}
									</IonCol>
									<IonCol style={{textAlign: "center", textTransform: "uppercase"}} size="4">
										<h3>Type: {machine.type}</h3>
									</IonCol>
									<IonCol style={{textAlign: "right"}} size="2">
                    <IonIcon style={{fontSize: "1.5rem"}} icon={personCircleOutline}></IonIcon>
									</IonCol>
									<IonCol style={{textAlign: "left", fontSize: "1.1rem", paddingLeft: "0"}} size="2">
                    {machine.userCount}
									</IonCol>
								</IonRow>
							</IonLabel>
						</IonItem>
						<IonItem detail={false} routerLink={`/tabs/speakers/sessions/${machine.idHrFpMachines}`}>
							<IonLabel>
								<h3>Work Station: {machine.workStationName} / House:{machine.houseNumber} Road: {machine.roadNumber}, {machine.location}</h3>
							</IonLabel>
						</IonItem>
					</IonList>
				</IonCardContent>
			</IonCard>
		</>
	);
};

export default MachineItem;
