import React, { useRef, useState } from 'react';

import { getConfig, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonModal, IonPage, IonProgressBar, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonViewWillEnter } from '@ionic/react';
import { options, search, syncOutline } from 'ionicons/icons';
import UserList from '../components/UserList';

import AttendanceLog from '../components/AttendanceLog';
import MachineSyncHistory from '../components/MachineSyncHistory';
import SessionListFilter from '../components/SessionListFilter';
import './MachineDetails.scss';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from 'react-hot-toast';
import { RouteComponentProps } from 'react-router';
import { connect } from '../data/connect';
import { Machine } from '../models/FingerprintMachine';
import Fetch from '../util/Fetch';
import Permission from '../util/Permission';

interface OwnProps { }

interface StateProps { }

interface DispatchProps {
}

interface MachineDetailsProps
	extends RouteComponentProps<{
		id: string;
	}> { }

const MachineDetailsPage: React.FC<MachineDetailsProps> = () => {
	const mode = "ios";
	const [searchText, setSearchText] = useState('');
	const [segment, setSegment] = useState<'users' | 'attendance'>('users');
	const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [showProgress, setShowProgress] = useState(true);
	const [showLoading, hideLoading] = useIonLoading();
	const [showIonAlert] = useIonAlert();
	const [machine, setMachine] = useState<Machine>();
	const [machineSyncHistory, setMachineSyncHistory] = useState<any[]>([]);
	const [syncPermission, setSyncPermission] = useState<boolean>(false);

	const [showModal, setShowModal] = useState(false);
	const [attendanceMessage, setAttendanceMessage] = useState('');

	const handleClose = () => setShowModal(false);
  	const handleShow = () => setShowModal(true);

	let getPendingAttendance = () => {
		showLoading();
		let syncQuery = `query{
			pendingAttendance(idHrFpMachines:${machine!.idHrFpMachines}){
				success
				message
			}
		}`

		Fetch(syncQuery).then(async data => {
			if (data.data.pendingAttendance.success) {
				setAttendanceMessage(data.data.pendingAttendance.message);
				handleShow();
			} else {
				showIonAlert({
					header: "Error",
					message: data.data.pendingAttendance.message,
					buttons: ["Ok"]
				})
			}
			hideLoading()
		});
	}

	let handleSubmitSyncData = () => {
		handleClose();
		syncMachineData();
	}

	let syncMachineData = () => {
		showLoading();
		let syncQuery = `mutation{
			syncMachine(idHrFpMachines:${machine!.idHrFpMachines}){
				success
				message
			}
		}`

		Fetch(syncQuery).then(async data => {
			if (data.data.syncMachine.success) {
				await fetchMachineData();
				await fetchMachineSyncHistory();
			} else {
				showIonAlert({
					header: "Error",
					message: data.data.syncMachine.message,
					buttons: ["Ok"]
				})
			}
			hideLoading()
		});
	}

	let fetchMachineData = async () => {
		setMachine(undefined);
		var id = window.location.pathname.split("/").pop();
		let machineQuery = `
		{
			machine(id:${id}){
				idHrFpMachines
				ipAddress
				workStationName
				type
				online
				lastSyncedOn
				machineUsers{
					uid
					avatar
					userPrivilege
					userGroup
					userId
					name
				}
				attendance{
					uid
					userId
					attendanceTimestamp
					name
				}
			}
		}
		`;
		setShowProgress(true);
		return Fetch(machineQuery).then(data => {
			setMachine(data.data.machine)
			fetchMachineSyncHistory();
			setTimeout(() => { setShowProgress(false); }, 1200);
		});
	}

	let fetchMachineSyncHistory = async () => {
		var id = window.location.pathname.split("/").pop();
		let machineSyncHistoryQuery = `
		{
			machineSyncHistory(id:${id}){
				idHrFpMachines
				ipAddress
				createDate
				status
				userCount
				attendanceCount
			}
		}
		`;
		return Fetch(machineSyncHistoryQuery).then(data => {
			setMachineSyncHistory(data.data.machineSyncHistory)
		});
	}

	let userInfo = JSON.parse(window.localStorage.getItem("hrFpUserInfo") || "{}");

	let checkPagePermission = async () => {
		await Permission(userInfo.idUsers, "machine_details").then(data => {
			if (!data.data.singlePagePermission.idHrFpUsersPermission) {
				toast.error("Access denied.");
				window.location.href = "/machines";
			}
		});
	}

	let checkSyncPermission = async () => {
		await Permission(userInfo.idUsers, "sync_machine").then(data => {
			if (data.data.singlePagePermission.idHrFpUsersPermission) {
				setSyncPermission(true);
			} else {
				setSyncPermission(false);
			}
		});
	}

	useIonViewWillEnter(async () => { 
		await checkPagePermission();
		await checkSyncPermission();
		fetchMachineData(); 
	});

	const pageRef = useRef<HTMLElement>(null);

	return (
		<IonPage ref={pageRef} id="schedule-page">
			<Toaster position="top-right" reverseOrder={false}/>
			<IonHeader translucent={true}>
				{showProgress ? (<IonProgressBar type="indeterminate"></IonProgressBar>) : null}
				<IonToolbar>
					{!showSearchbar &&
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
					}
					{!showSearchbar &&
						<IonTitle>Machine User Data and Attendence <strong>({machine ? machine.ipAddress : ""})</strong> / Last Synced On:  ({machine ? machine.lastSyncedOn : ""})</IonTitle>
					}
					{showSearchbar &&
						<IonSearchbar showCancelButton="always" placeholder="Search" onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)} onIonCancel={() => setShowSearchbar(false)}></IonSearchbar>
					}

					<IonButtons slot="end">
						{!showSearchbar &&
							<IonButton onClick={() => setShowSearchbar(true)} title="Search">
								<IonIcon slot="icon-only" icon={search}></IonIcon>
							</IonButton>
						}
						{!showSearchbar &&
							<>
								{syncPermission ? <IonButton onClick={() => getPendingAttendance()} title="Sync">
									<IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
								</IonButton> : null }
								<IonButton onClick={() => setShowFilterModal(true)}>
									{mode === 'ios' ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
								</IonButton>
							</>
						}
					</IonButtons>
				</IonToolbar>
				<IonToolbar>
					<IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as any)}>
						<IonSegmentButton value="users">
							Users
						</IonSegmentButton>
						<IonSegmentButton value="attendance">
							Attendance
						</IonSegmentButton>
						<IonSegmentButton value="sync_history">
							Sync History
						</IonSegmentButton>						
					</IonSegment>
				</IonToolbar>

			</IonHeader>
			<IonContent fullscreen={false}>
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">Schedule</IonTitle>
					</IonToolbar>
					<IonToolbar>
						<IonSearchbar placeholder="Search" onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}></IonSearchbar>
					</IonToolbar>
				</IonHeader>

				{
					segment === 'users' ? (
						<UserList
							users={machine ? machine.machineUsers : undefined}
							listType={segment}
							idHrFpMachines={machine ? machine.idHrFpMachines : 0}
							searchText={searchText}
						/>
					) : segment === 'attendance' ? (
						<AttendanceLog
							// schedule={schedule}
							listType={segment}
							users={machine ? machine.machineUsers : undefined}
							attendance={machine ? machine.attendance : undefined}
							searchText={searchText}
						/>
					) : (
						<MachineSyncHistory
							syncHistory={machineSyncHistory ? machineSyncHistory : undefined}
							searchText={searchText}
						/>
					)
				}



			</IonContent>

			<IonModal
				isOpen={showFilterModal}
				onDidDismiss={() => setShowFilterModal(false)}
				swipeToClose={true}
				presentingElement={pageRef.current!}
				cssClass="session-list-filter"
			>
				<SessionListFilter
					onDismissModal={() => setShowFilterModal(false)}
				/>
			</IonModal>

			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
				<Modal.Title>Pending Attendance</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Total {attendanceMessage}</h4>
					<h5>Do you want to sync pending attendance?</h5>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSubmitSyncData}>
					Yes, Sync
				</Button>
				</Modal.Footer>
			</Modal>
		</IonPage>
	);
};

export default connect<OwnProps, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		mode: getConfig()!.get('mode')
	}),
	mapDispatchToProps: {
	},
	component: React.memo(MachineDetailsPage)
});
