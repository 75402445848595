import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { RouteComponentProps } from 'react-router';
import { connect } from '../data/connect';
import { WorkStation } from '../models/FingerprintMachine';
import Fetch from '../util/Fetch';
import Permission from '../util/Permission';
import './AddMachine.scss';

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
}

interface AddMachineProps extends OwnProps, DispatchProps { }

const AddMachine: React.FC<AddMachineProps> = () => {

	let [ipAddress, setIpAddress] = useState("");
	let [port, setPort] = useState("4370");
	let [type, setType] = useState("");
	let [workStationId, setWorkStationId] = useState();
	let [remarks, setRemarks] = useState("");
	const [workStations, setWorkStations] = useState<WorkStation[]>([]);
	const [showLoading, setShowLoading] = useState(false);
	const [showIonAlert] = useIonAlert();

	let fetchWorkStations = () => {
		let workStationQuery = `{
        workStations{
            workStationId
            workStationName
            houseNumber
            roadNumber
            location
        }
    }`;

		Fetch(workStationQuery).then(data => { setWorkStations(data.data.workStations) });
	}
	
	let userInfo = JSON.parse(window.localStorage.getItem("hrFpUserInfo") || "{}");

	let checkPermission = async () => {
		await Permission(userInfo.idUsers, "add_machine").then(data => {
			if (!data.data.singlePagePermission.idHrFpUsersPermission) {
				toast.error("Access denied.");
				window.location.href = "/machines";
			}
		});
	}

	useIonViewDidEnter(async () => { 
		await checkPermission();
		fetchWorkStations(); 
	})

	const addMachine = async (e: React.FormEvent) => {
		e.preventDefault();
		setShowLoading(true);
		let savemachineQuery = `mutation{
            machine(ipAddress:"${ipAddress}",
				port:"${port}",
				remarks:"${remarks}",
                workStationId: ${workStationId},
                type: "${type}"
                ){
                    success, message
                }
            }`;

		Fetch(savemachineQuery).then(data => {
			setShowLoading(false);
			if (data.data.machine.success) {
				showIonAlert({
					animated: true,
					mode: "ios",
					message: 'Machine added successfully',
					buttons: [{
						text: 'OK', handler: () => {
							window.location.href = "/machines";
						}
					}],
				})
			} else {
				showIonAlert({
					animated: true,
					mode: "ios",
					header: "Server error",
					message: data.data.machine ? data.data.machine.message: "",
					buttons: ['ok'],
				})
			}
		});
	};

	return (
		<IonPage id="add-machine">
			<Toaster position="top-right" reverseOrder={false}/>
			<IonHeader translucent={true}>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Add a new Machine</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen={false}>
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
				<IonGrid fixed>
					<form noValidate onSubmit={addMachine}>
						<IonRow>
							<IonCol size="8">
								<IonList lines="full" class="ion-no-margin">
									<IonListHeader lines="full">
										<IonLabel>
											Machine Information
										</IonLabel>
									</IonListHeader>
									<IonItem>
										<IonLabel position="floating">Machine IP Address</IonLabel>
										<IonInput name="ipAddress" type="text" value={ipAddress} onIonChange={e => setIpAddress(e.detail.value!)} required></IonInput>
									</IonItem>
									<IonItem>
										<IonLabel position="floating">Machine Port</IonLabel>
										<IonInput name="port" type="text" value={port} onIonChange={e => setPort(e.detail.value!)} required></IonInput>
									</IonItem>
									<IonItem>
										<IonLabel>Type</IonLabel>
										<IonSelect name="type" value={type} onIonChange={e => setType(e.detail.value!)} interface="alert">
											<IonSelectOption value="fixed">Fixed</IonSelectOption>
											<IonSelectOption value="floating">Floating</IonSelectOption>
										</IonSelect>
									</IonItem>
									<IonItem>
										<IonLabel>Work Station</IonLabel>
										<IonSelect name="workStationId" value={workStationId} onIonChange={e => setWorkStationId(e.detail.value!)} interface="alert">
											{
												workStations ? (workStations.map(workStation => (
													<IonSelectOption key={`wsi_${workStation.workStationId}`} value={workStation.workStationId}>{workStation.workStationName}</IonSelectOption>
												))) : null
											}
										</IonSelect>
									</IonItem>
								</IonList>

								<IonItem>
									<IonLabel position="floating">Remarks</IonLabel>
									<IonTextarea name="remarks" value={remarks} onIonChange={e => setRemarks(e.detail.value!)} required></IonTextarea>
								</IonItem>
								<IonButton type="submit" style={{ "marginTop": "25px" }} expand="full" color="tertiary">Save Machine</IonButton>
							</IonCol>
						</IonRow>
					</form>
				</IonGrid>
			</IonContent>
		</IonPage>
	)
};

export default connect<OwnProps, {}, DispatchProps>({
	mapDispatchToProps: {
	},
	component: AddMachine
})
