let Fetch = async (query : string)=>{
	return (fetch(process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : process.env.react_app_api_url!, {
		method: "post",
		body: JSON.stringify({ "query": query, "variables": null }),
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': "Bearer "+window.localStorage.getItem("token")
		}
	}).then(response => {
		if(response.status === 401){
			window.localStorage.removeItem("token");
			window.location.href="/logout";
		}

		return response.json()
	})
	.catch(e => {console.log(e)})
	.then(data=>data.errors?{data:{success: false, message: data.errors.join(",")}}:data))
}

export default Fetch;