import { actionSheetController } from '@ionic/core';
import { IonAvatar, IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonToolbar, useIonAlert, useIonViewWillEnter } from '@ionic/react';
import { caretForwardCircle, close, share, trash } from 'ionicons/icons';
import React, { useState } from 'react';
import { connect } from '../data/connect';
import { Employee, Machine, MachineUser } from '../models/FingerprintMachine';
import Fetch from '../util/Fetch';


interface OwnProps {
	listType: 'users' | 'attendance';
	users: MachineUser[] | undefined;
	idHrFpMachines: number;
	searchText: string;
}

interface StateProps {

}

interface UserListProps extends OwnProps, StateProps { };

const UserList: React.FC<UserListProps> = ({ users, idHrFpMachines, searchText }) => {
	let ios = true;
	// Machine privilege
	let privileges: string[] = [];
	privileges[0] = "Default user";
	privileges[2] = "User Enroller";
	privileges[6] = "User Manager";
	privileges[14] = "Admin";

	const [showEmployeeModal, setShowEmployeeModal] = useState(false);
	const [showMachineModal, setShowMachineModal] = useState(false);
	let [employees, setEmployees] = useState<Employee[]>();
	let [employeeClicked, setEmployeeClicked] = useState<boolean[]>([]);
	let [currentlySelectedEmployee, setCurrentlySelectedEmployee] = useState<Employee>();
	let [machines, setMachines] = useState<Machine[]>();
	let [currentlySelectedMachine, setCurrentlySelectedMachine] = useState<Machine>();
	let [currentlySelectedUserUid, setCurrentlySelectedUserUid] = useState<number>();
	let [currentlySelectedUser, setCurrentlySelectedUser] = useState<MachineUser>();
	const [showLoading, setShowLoading] = useState(false);
	const [showIonAlert] = useIonAlert();

	let fetchMachineData = () => {
		Fetch("{machines{idHrFpMachines, ipAddress, workStationName}}").then(data => { setMachines(data.data.machines) });
	}

	useIonViewWillEnter(() => { fetchMachineData() });

	let searchTimeout = window.setTimeout(() => { }, 500);
	let fetchEmployeeData = (name: string) => {
		let employeeQuery = `{
			employees(name: "${name}"){
					employeeCustomId
					fullName
					designationTitle
					department
					avatar
				}
			}`;

		window.clearTimeout(searchTimeout);
		console.log(searchTimeout);
		searchTimeout = window.setTimeout(() => { Fetch(employeeQuery).then(data => { setEmployees(data.data.employees); }); }, 500);
	}

	let copyUserToMachine = () => {
		let copyQuery = `mutation{
			copyMachineUser(idHrFpMachines:${idHrFpMachines}, targetMachineId:${currentlySelectedMachine!.idHrFpMachines}, userId: "${currentlySelectedUser!.userId}"){
				success
				message
			}
		}`

		setShowLoading(true);

		Fetch(copyQuery).then(data => {
			setShowLoading(false);
			showIonAlert({
				animated: true,
				mode: "ios",
				message: 'User copied to new machine',
				buttons: [{
					text: 'OK', handler: () => {
						window.location.reload();
					}
				}],
			})
		});
	}

	let updateEmployee = () => {
		let employeeUpdateQuery = `mutation{
			machineUser(idHrFpMachines: ${idHrFpMachines},
				name:"${currentlySelectedEmployee ? currentlySelectedEmployee.fullName : ""}"
				uid: ${currentlySelectedUserUid},
				userId: "${currentlySelectedEmployee ? currentlySelectedEmployee.employeeCustomId : ""}"
			){
				success
				message
			}
		}`

		Fetch(employeeUpdateQuery).then(data => {
			if (data.data.machineUser.success && data.data.machineUser.success === true) {
				showIonAlert({
					animated: true,
					mode: "ios",
					message: 'Machine user updated',
					buttons: [{
						text: 'OK', handler: () => {
							window.location.reload();
						}
					}],
				})
			} else {
				showIonAlert({
					animated: true,
					mode: "ios",
					message: data.data.machineUser ? data.data.machineUser.message : 'Error updating machine user',
					buttons: ['Ok'],
				})
			}
		});
	}

	let updateEmployeeClicked = (index: number) => {
		if (employees) {
			setCurrentlySelectedEmployee(employees[index])
		};
		let allData = employeeClicked;
		allData.forEach((value, index) => { allData[index] = false; });
		allData[index] = !employeeClicked[index];
		setEmployeeClicked([...allData]);
		console.log(index);
	}

	let deleteUser = (idHrFpMachines: number, userId: string) => {
		let deleteEmployeeQuery = `mutation{
			deleteMachineUser(idHrFpMachines:${idHrFpMachines}, userId:"${userId}"){
				success
				message
			}
		}`

		Fetch(deleteEmployeeQuery).then(data => {
			showIonAlert({
				animated: true,
				mode: "ios",
				message: 'User Deleted',
				buttons: [{
					text: 'OK', handler: () => {
						window.location.reload();
					}
				}],
			})
		});
	}

	async function handleButtonClick(idHrFpMachines: number, userId: string, uid: number, user: MachineUser) {
		setCurrentlySelectedUserUid(uid);
		setCurrentlySelectedUser(user);

		const actionSheet = await actionSheetController.create({
			header: 'Actions',
			animated: true,
			mode: "ios",
			buttons: [
				{ text: 'Delete user', role: 'destructive', icon: trash, handler: () => { deleteUser(idHrFpMachines, userId) } },
				{ text: 'Copy user to Machine', icon: share, handler: () => { setShowMachineModal(true) } },
				{ text: 'Connect to HRIS User', icon: caretForwardCircle, handler: () => { setShowEmployeeModal(true) } },
				{ text: 'Cancel', role: 'cancel', icon: close, }
			]
		});

		await actionSheet.present();
	}

	if (users && users.length === 0) {
		return (
			<IonList>
				<IonListHeader>
					No User Found
				</IonListHeader>
			</IonList>
		);
	}

	return (
		<>

			{/* Modal for employee selection */}
			<IonModal
				isOpen={showEmployeeModal}
				onDidDismiss={() => setShowEmployeeModal(false)}
				swipeToClose={true}
				cssClass="session-list-filter"
			>
				<IonHeader translucent={true}>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => { setShowEmployeeModal(false) }}>Cancel</IonButton>
						</IonButtons>

						<IonInput onIonChange={(e) => { fetchEmployeeData(e.detail.value!) }} placeholder="Search for employee..."></IonInput>
						<IonButtons slot="end">
							<IonButton color={!currentlySelectedEmployee ? "error" : "success"} disabled={!currentlySelectedEmployee} onClick={() => { updateEmployee() }} strong>Done</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					<IonList lines={ios ? 'inset' : 'full'}>
						{employees ? employees.map((employee, index) => {
							return (
								<IonItem key={employee.employeeCustomId}>
									<IonAvatar slot="start">
										{employee.avatar ? (<img alt="avatar" src={(process.env.react_app_image_url ? process.env.react_app_image_url : process.env.REACT_APP_IMAGE_URL) + employee.avatar.split(".")[0] + "_thumb." + employee.avatar.split(".")[1]} />) : (<img alt="avatar" src="assets/img/user.png" />)}
									</IonAvatar>
									<IonLabel>
										<h2>{employee.fullName}</h2>
										<h3>{employee.employeeCustomId}</h3>
										<p>{employee.designationTitle} / {employee.department}</p>
									</IonLabel>
									<IonCheckbox
										mode="ios"
										onClick={() => { updateEmployeeClicked(index) }}
										checked={employeeClicked[index]}
										color="danger"
										value={index.toString()}
									></IonCheckbox>
								</IonItem>
							)
						}) : null}
					</IonList>
				</IonContent>
			</IonModal>

			{/* Modal for machine selection selection */}
			<IonModal
				isOpen={showMachineModal}
				onDidDismiss={() => setShowMachineModal(false)}
				swipeToClose={true}
				cssClass="session-list-filter"
			>
				<IonHeader translucent={true}>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => { setShowMachineModal(false) }}>Cancel</IonButton>
						</IonButtons>
						<IonButtons slot="end">
							<IonButton onClick={() => { copyUserToMachine() }} strong>Done</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
					<IonList lines="inset">
						{!machines ? null : machines.map((machine, index) => (
							<IonItem key={machine.idHrFpMachines}>
								<IonLabel>
									<h2>{machine.ipAddress}</h2>
									<p>{machine.workStationName}</p>
								</IonLabel>
								<IonCheckbox
									mode="ios"
									onClick={() => { setCurrentlySelectedMachine(machine) }}
									color="danger"
									value={index.toString()}
								></IonCheckbox>
							</IonItem>
						))}
					</IonList>
				</IonContent>
			</IonModal>

			<IonList style={false ? { display: 'none' } : {}}>
				{users ? users.map((user, index: number) => {
					if(searchText && user.userId.toLowerCase().indexOf(searchText.toLowerCase()) === -1 && user.name.toLowerCase().indexOf(searchText.toLowerCase()) === -1){
						return null;
					}
					return(
					<IonItem key={user.userId} onClick={() => { handleButtonClick(idHrFpMachines, user.userId, user.uid, user) }} button={true}>
						<IonAvatar slot="start">
							{user.avatar ? (<img alt="avatar" src={(process.env.react_app_image_url ? process.env.react_app_image_url : process.env.REACT_APP_IMAGE_URL) + user.avatar.split(".")[0] + "_thumb." + user.avatar.split(".")[1]} />) : (<img alt="avatar" src="assets/img/user.png" />)}
						</IonAvatar>
						<IonLabel>
							<h2>{user.name}</h2>
							<h3>{privileges[user.userPrivilege]}</h3>
							<p>{user.userId}</p>
						</IonLabel>
					</IonItem>)}) : (<IonItem><IonLabel><h1>NO USER FOUND</h1></IonLabel></IonItem>)}
			</IonList>
		</>
	);
};

export default connect<OwnProps>({
	component: UserList
});
