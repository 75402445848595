import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { RouteComponentProps } from 'react-router';
import { connect } from '../data/connect';
import { Machine } from '../models/FingerprintMachine';
import Fetch from '../util/Fetch';
import Permission from '../util/Permission';
import './ImportUser.scss';

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
}

interface ImportUserProps extends OwnProps, DispatchProps { }

function addslashes(str: String) {
  // eslint-disable-next-line no-control-regex
	return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
}

const ImportUser: React.FC<ImportUserProps> = () => {

	const [showLoading, setShowLoading] = useState(false);
	const [showIonAlert] = useIonAlert();
	const [machines, setmachines] = useState<Machine[]>([]);
	let [idHrFpMachines, selectMachine] = useState<Number>();
	let [importData, setImportData] = useState<String>();

	let fetchMachineData = () => {
		let machineListQuery = "{machines{idHrFpMachines,ipAddress,workStationName}}";

		Fetch(machineListQuery).then(data => { setmachines(data.data.machines) });
	}

	let userInfo = JSON.parse(window.localStorage.getItem("hrFpUserInfo") || "{}");

	let checkPermission = async () => {
		await Permission(userInfo.idUsers, "machine_sync_history").then(data => {
			if (!data.data.singlePagePermission.idHrFpUsersPermission) {
				toast.error("Access denied.");
				window.location.href = "/machines";
			}
		});
	}
	
	useIonViewDidEnter(async () => { 
		await checkPermission();
		fetchMachineData(); 
	});

	let setUserFile = (fileObject: any) => {
		let element = document.getElementById('userFile') as HTMLInputElement;

		let file = element.files![0];
		var reader = new FileReader();
		reader.readAsText(file, "UTF-8");
		reader.onload = evt => { setImportData(evt.target?.result?.toString().split(/\r?\n/).join("|")) }
	}

	let importExcel = (e: React.FormEvent) => {

		e.preventDefault();
		setShowLoading(true);
		let savemachineQuery = `mutation{
            importMachineUser(idHrFpMachines:${idHrFpMachines},importData:"${addslashes(importData!)}"){
                    success, message
                }
            }`;

		Fetch(savemachineQuery).then(data => {
			setShowLoading(false);
			if (data.data.importMachineUser && data.data.importMachineUser.success) {
				showIonAlert({
					animated: true,
					mode: "ios",
					message: data.data.importMachineUser.message,
					buttons: [{
						text: 'OK', handler: () => {
							window.location.href = "/machines";
						}
					}],
				})
			} else {
				showIonAlert({
					animated: true,
					mode: "ios",
					header: "Server error",
					message: data.data.importMachineUser ? data.data.importMachineUser.message : "",
					buttons: ['ok'],
				})
			}
		});
	}

	return (
		<IonPage id="add-machine">
			<Toaster position="top-right" reverseOrder={false}/>
			<IonHeader translucent={true}>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Import User to Machine</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen={false}>
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
				<IonGrid fixed>
					<form noValidate onSubmit={importExcel}>
						<IonRow>
							<IonCol size="8">
								<IonList lines="full" class="ion-no-margin">
									<IonListHeader lines="full">
										<IonLabel>
											Import Data
										</IonLabel>
									</IonListHeader>
									<IonItem>
										<IonLabel position="floating">You can only use CSV file</IonLabel>
										<input style={{ "marginTop": "30px", "marginBottom": "10px" }} id="userFile" name="userFile" type="file" onChange={e => setUserFile(e)} required></input>
									</IonItem>
									<IonItem>
										<IonLabel>Fingerprint Machine</IonLabel>
										<IonSelect name="machineId" value={idHrFpMachines} onIonChange={e => selectMachine(e.detail.value!)} interface="alert">
											{
												machines ? (machines.map(machine => (
													<IonSelectOption key={`wsi_${machine.idHrFpMachines}`} value={machine.idHrFpMachines}>{machine.ipAddress}({machine.workStationName})</IonSelectOption>
												))) : null
											}
										</IonSelect>
									</IonItem>
								</IonList>

								<IonButton disabled={!idHrFpMachines || !importData} type="submit" style={{ "marginTop": "25px" }} expand="full" color="tertiary">Start Import</IonButton>
							</IonCol>
						</IonRow>
					</form>
				</IonGrid>
			</IonContent>
		</IonPage>
	)
};

export default connect<OwnProps, {}, DispatchProps>({
	mapDispatchToProps: {
	},
	component: ImportUser
})
