import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import MachineItem from '../components/MachineItem';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { Machine } from '../models/FingerprintMachine';
import Fetch from '../util/Fetch';
import Permission from '../util/Permission';
import './MachineList.scss';

interface OwnProps { };

interface StateProps {
};


interface DispatchProps { };

interface MachineListProps extends OwnProps, StateProps, DispatchProps { };

const MachineList: React.FC<MachineListProps> = () => {
	const [machines, setmachines] = useState<Machine[]>([]);

	let fetchData = () => {
		let machineListQuery = "{machines{ipAddress,idHrFpMachines,userCount,machineDeviceName,workStationName,roadNumber,houseNumber,location,type,online}}";

		Fetch(machineListQuery).then(data => {setmachines(data.data.machines)});
	}

	let userInfo = JSON.parse(window.localStorage.getItem("hrFpUserInfo") || "{}");

	let checkPermission = async () => {
		await Permission(userInfo.idUsers, "machines").then(data => {
			if (!data.data.singlePagePermission.idHrFpUsersPermission) {
				window.localStorage.removeItem('hrFpUserMenu');
				window.localStorage.removeItem('hrFpUserInfo');
				window.localStorage.removeItem('token');
				window.location.href="/login";
			}
		});
	}

	useIonViewWillEnter(async () => {
		await checkPermission();
		fetchData();
	});


	return (
		<IonPage id="speaker-list">
			<IonHeader translucent={true}>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Machines</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen={true}>
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">Machines</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonGrid>
					<IonRow>
						{
							machines.length === 0 ? (<h1 style={{"marginLeft":"35%","marginTop":"150px"}}>NO MACHINE FOUND</h1>) : (machines.map(machine => (
								<IonCol size="12" size-md="4" key={machine.idHrFpMachines}>
									<MachineItem
										key={machine.idHrFpMachines}
										machine={machine}
									/>
								</IonCol>
							)))
						}
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default connect<OwnProps, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		speakers: selectors.getSpeakers(state),
		speakerSessions: selectors.getSpeakerSessions(state)
	}),
	component: React.memo(MachineList)
});
